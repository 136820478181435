import React from "react";
import { LocaleAsString } from "../../../localeEngine";
import Icon from "../../../components/icons";
import { Button, Typography } from "../../utils";
import * as styles from "./styles.module.scss";

const EditButton = ({ onClick }) => (
  <Button className={styles.editBtn} onClick={onClick}>
    <Icon icon="Edit" size="Small" />
    <Typography variant="h400-medium" component="p" className={styles.label}>
      <LocaleAsString id="edit_button.edit" />
    </Typography>
  </Button>
);

export default EditButton;
