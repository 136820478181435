import React from "react";
import { navigate } from "gatsby-link";
import CountryFunction from "country-list-js";

import Typography from "../../../../components/utils/typography";
import ProductList from "../../../../components/checkout/productList";

import EditButton from "../../../../components/checkout/editButton";

import { getSession } from "../../../../utils/localStorage";

import { LocaleAsString } from "../../../../localeEngine";

import * as styles from "./styles.module.scss";

const OrderDetails = (props) => {
  const session = getSession();
  if (typeof session === "undefined") {
    return <div></div>;
  }

  if (!session.customer) {
    navigate("/cart");
  }

  const findCountry = (country) => {
    const foundCountry = CountryFunction.findByIso3(country);
    if (foundCountry && foundCountry.name) {
      return foundCountry.name;
    }

    return country;
  };

  const {
    firstName,
    lastName,
    email,
    prefix,
    addressLine1,
    phone,
    city,
    postalCode,
    country,
  } = session.customer;

  return (
    <div className={styles.orderDetails}>
      <div className={styles.summary}>
        <Typography variant="h400-medium" component="span">
          <LocaleAsString id="my_account.menu.order" /> #{session.id}
        </Typography>
      </div>
      <div className={styles.box}>
        <div className={styles.header}>
          <Typography component="h3" variant="h500-medium">
            <LocaleAsString id="checkout.summary.title" />
          </Typography>
          <EditButton onClick={() => props.setCurrentStep(1)} />
        </div>
        <div className={styles.details}>
          <div className={styles.detail}>
            <Typography
              className={styles.label}
              component="span"
              variant="h300-medium"
            >
              <LocaleAsString id="checkout.guest_details.form.first_name" />
            </Typography>
            <Typography component="span" variant="h300-medium">
              {firstName}
            </Typography>
          </div>
          <div className={styles.detail}>
            <Typography
              className={styles.label}
              component="span"
              variant="h300-medium"
            >
              <LocaleAsString id="checkout.guest_details.form.last_name" />
            </Typography>
            <Typography component="span" variant="h300-medium">
              {lastName}
            </Typography>
          </div>
          <div className={styles.detail}>
            <Typography
              className={styles.label}
              component="span"
              variant="h300-medium"
            >
              <LocaleAsString id="checkout.guest_details.form.email" />
            </Typography>
            <Typography component="span" variant="h300-medium">
              {email}
            </Typography>
          </div>
          <div className={styles.detail}>
            <Typography
              className={styles.label}
              component="span"
              variant="h300-medium"
            >
              <LocaleAsString id="checkout.guest_details.form.phone_number" />
            </Typography>
            <Typography component="span" variant="h300-medium">
              {prefix} {phone}
            </Typography>
          </div>
          <div className={styles.detail}>
            <Typography
              className={styles.label}
              component="span"
              variant="h300-medium"
            >
              <LocaleAsString id="checkout.guest_details.form.address" />
            </Typography>
            <Typography component="span" variant="h300-medium">
              {addressLine1}
            </Typography>
          </div>

          <div className={styles.detail}>
            <Typography
              className={styles.label}
              component="span"
              variant="h300-medium"
            >
              <LocaleAsString id="checkout.guest_details.form.postal_code" />
            </Typography>
            <Typography component="span" variant="h300-medium">
              {postalCode}
            </Typography>
          </div>

          <div className={styles.detail}>
            <Typography
              className={styles.label}
              component="span"
              variant="h300-medium"
            >
              <LocaleAsString id="checkout.guest_details.form.city" />
            </Typography>
            <Typography component="span" variant="h300-medium">
              {city}
            </Typography>
          </div>

          <div className={styles.detail}>
            <Typography
              className={styles.label}
              component="span"
              variant="h300-medium"
            >
              <LocaleAsString id="checkout.guest_details.form.country" />
            </Typography>
            <Typography component="span" variant="h300-medium">
              {findCountry(country)}
            </Typography>
          </div>
        </div>
      </div>
      <ProductList products={getSession().products} />
    </div>
  );
};

export default OrderDetails;
