import React from "react";
import moment from "moment";
import { LocaleAsString } from "../../../localeEngine";
import Icon from "../../icons";
import Currency from "../../currency";
import { Typography, Button } from "../../utils";
import * as styles from "./styles.module.scss";
import { EMAIL_REGEX } from "../../forms/input/regex";

interface ProductCellProps {
  product: any;
  setDeleteModal?: any;
  updateQuantity?: any;
  loadingDelete?: number;
  missingPassholders?: any;
}

const ProductCell = (props: ProductCellProps) => {
  const {
    setDeleteModal,
    updateQuantity,
    loadingDelete,
    missingPassholders = false,
  } = props;
  let { product } = props;

  if (!product.quantity) {
    product.quantity = 1;
  }

  const getAttribute = (product, type) => {
    let attribute = product.attributeList.find(
      (attr) => attr.attribute === type
    );

    if (attribute && attribute.value) {
      attribute = attribute.value;
    } else {
      attribute = "--";
    }

    return attribute;
  };

  const getTotalPrice = () => {
    let total =
      parseFloat(getAttribute(product, "unitPrice")) * product.quantity;

    if (product.bundleSettings && product.bundleSettings.active) {
      product.bundleProducts.forEach((prod) => {
        const { unitPrice, quantity } = prod;
        total += parseFloat(unitPrice) * quantity;
      });
    }
    return total;
  };

  const hasBundlesToDisplay = () => {
    let hasBundles = 0;
    if (product.bundleSettings && product.bundleSettings.active) {
      product.bundleProducts.forEach((bundle) => {
        hasBundles += bundle.quantity;
      });
    }
    return hasBundles > 0;
  };

  const getKeyValue = (subtitle) => {
    if (EMAIL_REGEX.test(subtitle)) {
      return (
        <LocaleAsString id="lift_tickets.form.keycard.modal.bluetooth_title" />
      );
    }
    if (subtitle === "") {
      return <LocaleAsString id="lift_tickets.keycard.new" />;
    }

    return <LocaleAsString id="lift_tickets.keycard.reload" />;
  };

  const returnDetails = () => {
    const { date } = product;

    return (
      <div className={styles.details}>
        {product.fulfilled !== true && (
          <div className={styles.detailsRow}>
            <Typography
              component="span"
              variant="h300-medium"
              wrap={false}
            ></Typography>
            <Typography component="span" variant="h300-medium">
              {getAttribute(product, "guests")}
            </Typography>
          </div>
        )}
        {date && (
          <div className={styles.detailsRow}>
            <Typography component="span" variant="h300-medium">
              Date
            </Typography>
            <Typography component="span" variant="h300-medium">
              {moment.utc(date).format("DD/MM/YYYY")}
            </Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.product}>
      {loadingDelete === product.itemId && (
        <div className={styles.blurBackground} />
      )}
      <div className={styles.header}>
        <div className={styles.image}>
          <img alt={product.name} src={product.image.url} />
        </div>
        <div className={styles.name}>
          <Typography
            className={styles.provider}
            component="p"
            variant="h300-medium"
          >
            {getAttribute(product, "supplier")}
          </Typography>
          <Typography component="p" variant="h500-heavy">
            {product.name}
          </Typography>
          {returnDetails()}
          {product.fulfilled === true && (
            <div className={styles.customerRequiriments}>
              {product.customerRequirements.map((req, idx) => {
                const { type, requirements } = req;
                const guest =
                  product.customerRequirements
                    .filter((customer) => customer.type === req.type)
                    .indexOf(req) + 1;
                const firstName = requirements.find(
                  (requirement) =>
                    requirement.identifier === "first-name-required"
                ).value;
                const lastName = requirements.find(
                  (requirement) =>
                    requirement.identifier === "last-name-required"
                ).value;

                const types = {
                  isAdult: "Adult",
                  isJunior: "Junior",
                };

                if (product.customerRequirements.length === 1) {
                  return (
                    <div
                      className={`${styles.customerRequiriment} ${styles.singlePassholder}`}
                      key={`product_customer-requirements_${idx}`}
                    >
                      <Typography variant="h300-medium" component="span">
                        Passholder
                      </Typography>
                      <Typography variant="h300-medium" component="span">
                        {`${firstName} ${lastName}`}
                      </Typography>
                    </div>
                  );
                }

                return (
                  <div
                    className={styles.customerRequiriment}
                    key={`product_customer-requirements_${idx}`}
                  >
                    <Typography variant="h300-medium" component="span">
                      {`${types[type]} ${guest}`}
                    </Typography>
                    <Typography variant="h300-medium" component="span">
                      {`${firstName} ${lastName}`}
                    </Typography>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {(setDeleteModal || hasBundlesToDisplay()) && (
        <div className={styles.passPrice}>
          <div className={styles.passQuantity}>
            <Icon icon="X" size="S" />
            <Typography component="span" variant="h500-medium">
              {product.quantity}
            </Typography>
          </div>
          <Currency
            template="checkoutBundle"
            currency={getAttribute(product, "currency")}
            price={getAttribute(product, "unitPrice") * product.quantity}
            className={styles.price}
          />
        </div>
      )}
      {setDeleteModal || hasBundlesToDisplay() ? (
        product.bundleSettings && product.bundleSettings.active ? (
          <div className={styles.bundles}>
            {product.bundleSettings && product.bundleSettings.active ? (
              <span>
                <Typography component="p" variant="h400-medium">
                  {product.bundleSettings.title}
                </Typography>
              </span>
            ) : null}
            {product.bundleSettings &&
            product.bundleSettings.active &&
            product.bundleProducts
              ? product.bundleProducts.map((prod, idx) => {
                  const { name, quantity, currency, unitPrice, subtitle } =
                    prod;
                  const keycardNumber = subtitle;
                  const thereIsAnotherBundle =
                    idx < product.bundleProducts.length - 1;
                  return (
                    <React.Fragment key={`product-bundle-${idx}`}>
                      <div className={styles.bundleDetails}>
                        <div className={styles.bottomDetails}>
                          {setDeleteModal ? (
                            <div className={styles.bundleQuantityColumn}>
                              {prod.id === 99 && (
                                <Typography component="p" variant="h400-medium">
                                  {getKeyValue(subtitle)}
                                </Typography>
                              )}
                              {keycardNumber && (
                                <Typography component="p" variant="h400-medium">
                                  {keycardNumber}
                                </Typography>
                              )}
                              {prod.quantity !== 1 && (
                                <div className={styles.quantitySelector}>
                                  {prod.quantity > 1 ? (
                                    <button
                                      onClick={() =>
                                        updateQuantity({
                                          itemId: product.itemId,
                                          bundleId: prod.id,
                                          quantity: prod.quantity - 1,
                                        })
                                      }
                                      className={styles.lessQuantity}
                                    >
                                      <Icon
                                        icon="Minus"
                                        size="Small"
                                        color={"#242426"}
                                      />
                                    </button>
                                  ) : (
                                    product.minQuantity < prod.quantity && (
                                      <button
                                        style={{
                                          display:
                                            prod.quantity === 0
                                              ? "none"
                                              : "inline",
                                        }}
                                        onClick={() =>
                                          updateQuantity({
                                            itemId: product.itemId,
                                            bundleId: prod.id,
                                            quantity: 0,
                                          })
                                        }
                                        className={styles.lessQuantity}
                                      >
                                        <Icon
                                          icon="Trash"
                                          size="Small"
                                          color={"#242426"}
                                        />
                                      </button>
                                    )
                                  )}

                                  {product.productCategory !== 3 && (
                                    <button
                                      onClick={
                                        prod.maxQuantity * product.quantity ===
                                        prod.quantity
                                          ? null
                                          : () =>
                                              updateQuantity({
                                                itemId: product.itemId,
                                                bundleId: prod.id,
                                                quantity: prod.quantity + 1,
                                              })
                                      }
                                      className={styles.moreQuantity}
                                    >
                                      <Icon
                                        icon="Plus"
                                        size="Small"
                                        color={
                                          prod.maxQuantity *
                                            product.quantity ===
                                          prod.quantity
                                            ? "lightgray"
                                            : "#242426"
                                        }
                                      />
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className={styles.bundleQuantityColumn}>
                              {/* <div className={styles.quantity}>
                                <Icon icon="X" size="S" />
                                <Typography
                                  component={"span"}
                                  className={styles.value}
                                  variant="h500-medium"
                                >
                                  {prod.quantity}
                                </Typography>
                              </div> */}
                              {prod.id === 99 && (
                                <Typography component="p" variant="h400-medium">
                                  {getKeyValue(subtitle)}
                                </Typography>
                              )}
                              {keycardNumber && (
                                <Typography component="p" variant="h400-medium">
                                  {keycardNumber}
                                </Typography>
                              )}
                            </div>
                          )}
                          <Currency
                            className={styles.bundleCurrency}
                            template="checkoutBundle"
                            currency={currency}
                            price={unitPrice * quantity}
                          />
                        </div>
                      </div>
                      {thereIsAnotherBundle && (
                        <div className={styles.bundleDivider} />
                      )}
                    </React.Fragment>
                  );
                })
              : null}
          </div>
        ) : null
      ) : null}
      <div className={styles.total}>
        {setDeleteModal ? (
          <div className={styles.quantitySelector}>
            {product.quantity > 1 ? (
              <div
                onClick={() =>
                  updateQuantity({
                    itemId: product.itemId,
                    quantity: product.quantity - 1,
                  })
                }
                className={styles.lessQuantity}
              >
                <Icon icon="Trash" size="Small" color={"#242426"} />
              </div>
            ) : (
              <div
                onClick={() => setDeleteModal(product)}
                className={styles.lessQuantity}
              >
                <Icon icon="Trash" size="Small" color={"#242426"} />
              </div>
            )}
            <div className={styles.quantity}>
              <Typography
                component={"span"}
                className={styles.value}
                variant="h600-medium"
              >
                {product.quantity}
              </Typography>
            </div>
            {product.productCategory !== 3 && (
              <div
                onClick={() =>
                  updateQuantity({
                    itemId: product.itemId,
                    quantity: product.quantity + 1,
                  })
                }
                className={styles.moreQuantity}
              >
                <Icon icon="Plus" size="Small" color={"#242426"} />
              </div>
            )}
          </div>
        ) : (
          !hasBundlesToDisplay() && (
            <div className={styles.quantity}>
              <Icon icon="X" size="S" />
              <Typography
                component={"span"}
                className={styles.value}
                variant="h500-medium"
              >
                {product.quantity}
              </Typography>
            </div>
          )
        )}
        <Currency
          template="checkout"
          currency={getAttribute(product, "currency")}
          price={getTotalPrice()}
          className={styles.price}
        />
      </div>
      {missingPassholders && (
        <Button
          onClick={() => null}
          className={styles.passholdersCTA}
          contentClassName={styles.contentPassholdersCTA}
        >
          <Icon icon="ArrowRight" size="S" className={styles.hidden} />
          <Typography variant="h400-heavy" component="span">
            <LocaleAsString id="my_account.passholders.assigned" />
          </Typography>
          <Icon icon="ArrowRight" size="S" className={styles.passholdersIcon} />
        </Button>
      )}
    </div>
  );
};

export default ProductCell;
