// extracted by mini-css-extract-plugin
export var product = "styles-module--product--1DZF-";
export var blurBackground = "styles-module--blurBackground--1qsUG";
export var header = "styles-module--header--1Kemy";
export var image = "styles-module--image--1iQc9";
export var name = "styles-module--name--2aPqf";
export var provider = "styles-module--provider--3fcMd";
export var details = "styles-module--details--3IAce";
export var detailsRow = "styles-module--detailsRow--27ftM";
export var customerRequiriments = "styles-module--customerRequiriments--3PJ5s";
export var customerRequiriment = "styles-module--customerRequiriment--1s3mg";
export var singlePassholder = "styles-module--singlePassholder--2RywJ";
export var passPrice = "styles-module--passPrice--1tFTR";
export var passQuantity = "styles-module--passQuantity--1tfXY";
export var bundles = "styles-module--bundles--vUiSz";
export var bundleSubtitle = "styles-module--bundleSubtitle--30lgA";
export var bundleDivider = "styles-module--bundleDivider--jbObi";
export var bundleDetails = "styles-module--bundleDetails--1l0A7";
export var bottomDetails = "styles-module--bottomDetails--2SOux";
export var bundleQuantityColumn = "styles-module--bundleQuantityColumn--1Pva8";
export var quantity = "styles-module--quantity--eOHfp";
export var bundleCurrency = "styles-module--bundleCurrency--2h5sz";
export var quantitySelector = "styles-module--quantitySelector--23-xQ";
export var lessQuantity = "styles-module--lessQuantity--3QrVr";
export var moreQuantity = "styles-module--moreQuantity--1YMl0";
export var value = "styles-module--value--37LJx";
export var divider = "styles-module--divider--2Gsgm";
export var passholdersCTA = "styles-module--passholdersCTA--37Nod";
export var contentPassholdersCTA = "styles-module--contentPassholdersCTA--1YaqB";
export var hidden = "styles-module--hidden--3jgHc";
export var passholdersIcon = "styles-module--passholdersIcon--3pxqr";
export var total = "styles-module--total--2Hr9Q";
export var price = "styles-module--price--3Ntk-";
export var currency = "styles-module--currency--1d22S";
export var int = "styles-module--int--2RsA_";
export var float = "styles-module--float--1Ko63";
export var bundleCTA = "styles-module--bundleCTA--1gmM3";
export var bundleRemove = "styles-module--bundleRemove--220wT";
export var bundleQuantity = "styles-module--bundleQuantity--1Z4MT";