import React from "react";
import { navigate } from "gatsby-link";
import { LocaleAsString } from "../../../localeEngine";

import { Typography } from "../../utils";

import ProductCell from "../productCell";
import EditButton from "../editButton";

import * as styles from "./styles.module.scss";
interface ProductListProps {
  products: Array<any>;
  setDeleteModal: Function;
  updateQuantity?: Function;
  loadingDelete?: number;
  isCompleted?: boolean;
}

const ProductList = (props: ProductListProps) => {
  const {
    setDeleteModal = false,
    products,
    updateQuantity,
    isCompleted = false,
    loadingDelete,
  } = props;
  return products ? (
    <div className={styles.box}>
      {!setDeleteModal && !isCompleted && (
        <div className={styles.header}>
          <EditButton onClick={() => navigate("/cart")} />
        </div>
      )}
      {products.length > 0 &&
        products.map((product, idx) => {
          const isLastProduct = idx + 1 === products.length;
          return (
            <React.Fragment key={`product_box_${idx}`}>
              <ProductCell
                loadingDelete={loadingDelete}
                updateQuantity={updateQuantity}
                product={product}
                setDeleteModal={setDeleteModal}
              />
              {!isLastProduct && (
                <div
                  className={styles.divider}
                  key={`product-divider_box_${idx}`}
                />
              )}
            </React.Fragment>
          );
        })}
    </div>
  ) : (
    <div className={styles.box}>
      <Typography component="h3" variant="h500-medium">
        <LocaleAsString id="cart.products.no_products_added_to_cart" />
      </Typography>
    </div>
  );
};

export default ProductList;
